
import { Vue, Component } from 'vue-property-decorator'
import { analyzeIDCard } from '@/utils/formatData'
import { WorkerDetail, FileInfo } from '@/types/worker'
import { ElForm } from 'element-ui/types/form'
import { idCardNo, phone, pInteger } from '@/utils/validate'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { UploadFile }
})
export default class Add extends Vue {
  private isEdit = false
  private isAdding = false
  private workTypeList: [] = []
  private workerTeamList: [] = []
  private workerInfo: WorkerDetail = {
    projectId: '',
    projectName: '',
    workerName: '',
    mobile: '',
    workerTeamId: '',
    sex: '',
    age: '',
    identityCard: '',
    homeAddress: '',
    emergencyPerson: '',
    emergencyPersonMobile: '',
    workType: '',
    resource: {
      fileName: '',
      filePrefix: '',
      fileUrl: ''
    }
  }

  private rules = {
    workerName: [
      { required: true, message: '请输入名称', whitespace: true }
    ],
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    identityCard: [
      { validator: idCardNo }
    ],
    mobile: [
      { validator: phone }
    ],
    age: [
      { validator: pInteger }
    ],
    emergencyPersonMobile: [
      { validator: phone }
    ]
  }

  private projectId = ''

  get projectList () {
    return this.$store.state.projectList
  }

  get workerId () {
    return this.$route.params.workerId || ''
  }

  created (): void {
    this.getDicList()
    this.$store.dispatch('getProjectList')
    if (this.workerId) {
      this.isEdit = true
      this.getWorkerInfo()
    }
  }

  getWorkerInfo () {
    this.$axios.get<WorkerDetail>(this.$apis.worker.selectProjectWorkerByWorkerId,
      { workerId: this.workerId }).then(res => {
      this.workerInfo = res || []
      this.projectId = res.projectId || ''
      this.getWorkerTeamList()
    })
  }

  getDicList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'workType' }).then(res => {
      this.workTypeList = res.workType || []
    })
  }

  getWorkerTeamList () {
    this.$axios.get(this.$apis.worker.selectProjectUserTeamByList, { projectId: this.workerInfo.projectId }).then(res => {
      this.workerTeamList = res.list || []
    })
  }

  identityCardChange (val: string) {
    const obj = analyzeIDCard(val)
    this.workerInfo.sex = obj.sex
    this.workerInfo.age = obj.age
  }

  projectChange () {
    this.workerInfo.workerTeamId = ''
    this.getWorkerTeamList()
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.workerInfo.resource = file
  }

  imgRemove () {
    this.workerInfo.resource = {
      fileName: '',
      filePrefix: '',
      fileUrl: ''
    }
  }

  submitForm () {
    (this.$refs.workerInfo as ElForm).validate(valid => {
      if (valid) {
        if (this.isEdit && this.projectId !== this.workerInfo.projectId) {
          this.bracelet()
        } else {
          this.addWorker()
        }
      }
    })
  }

  // 查询是否绑定手环
  bracelet () {
    this.$axios.get(this.$apis.worker.countBoundDevice, {
      workerId: this.workerInfo.workerId
    }).then(res => {
      // 1006 手环类型， 1023 工牌类型
      if (['1006', '1023'].includes(res)) {
        const params = res === '1006' ? {
          url: this.$apis.bracelet.unBindWatchWorker,
          name: '手环'
        } : {
          url: this.$apis.workcard.updateUnBindWorkCard,
          name: '工牌'
        }
        this.$confirm(`是否解除${params.name}绑定，并保存?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.$axios.post(params.url, {
            workerId: this.workerInfo.workerId
          }).then(() => {
            this.addWorker()
          })
        })
      } else {
        this.addWorker()
      }
    })
  }

  addWorker () {
    this.isAdding = true
    const url = this.isEdit ? this.$apis.worker.updateProjectWorker : this.$apis.worker.insertProjectWorker
    this.$axios.post(url, this.workerInfo).then(() => {
      this.$message.success(this.isEdit ? '修改成功' : '添加成功')
      this.goBack()
    }).finally(() => {
      this.isAdding = false
    })
  }

  goBack () {
    this.$router.push({ path: '/projectWorkerList' })
  }
}
